<template>
  <div class="s_pie">
    <div :style="{ height: `${height}px` }" ref="ele"></div>
  </div>
</template>
<script>
export default {
  props: {
    data: Array,
    name: String,
    desc: String,
    height: {
      type: String,
      default: "300",
    },
    raduis: {
      type: Number,
      default: 100,
    },
    scale: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
   
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let data=JSON.parse(JSON.stringify(this.data));
      let dataList = []
      data.map((item) => {
        item.name = item.name + "\n(" + item.compare + ")"
        dataList.push(item.value)
        item.max = 5
      })
      var option = {
        color: "#3A86FD",
        radar: {
          name: {
            color: "#333",
            fontSize: "16px",
          },
          radius: '70%',
          axisName: {
            fontSize: 14,
            color: '#333',
            
          },
          indicator: data
        },
        series: [
          {
            areaStyle: {},
            type: "radar",
            data: [
              {
                value: dataList
              },
            ],
          },
        ],
      };
      this.$echarts.init(this.$refs.ele).setOption(option);
    },
  },
  watch: {
    data() {
      this.init();
    },
  },
};
</script>
<style lang="less" scoped>
.s_pie {
  position: relative;
  padding-bottom: 20px;
  > .desc {
    position: absolute;
    z-index: 121;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .sq_box {
    position: absolute;
    bottom: 0;
    // transform: translateY(100%);
    display: flex;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    .item {
      display: flex;
      align-items: center;
      margin-left: 10px;
      .name {
        font-size: 12px;
        color: #333333;
        width: 49px;
      }
      .color {
        width: 12px;
        height: 12px;
        border-radius: 3px;
        margin-right: 10px;
      }
    }
  }
  > .scale {
    transform: translateX(-50%) scale(0.75);
  }
}
</style>
