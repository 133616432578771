const get_second_index_obj = (table_data) => {
  const second_index_obj = {};
  table_data.map((item) => {
    second_index_obj[item.second_index] = 0;
  });
  return second_index_obj;
};
const get_arr = (second_index, table_data, key) => {
  var arr = [];
  table_data.map((item) => {
    if (item.second_index == second_index) arr.push(item[key]);
  });
  return arr;
};
const set_table_data_2 = (second_index_obj, table_data) => {
  const table_data_2 = [];
  Object.keys(second_index_obj).map((second_index) => {
    return table_data_2.push({
      second_index: second_index,
      outlier: get_arr(second_index, table_data, "outlier"),
      图标: get_arr(second_index, table_data, "图标"),
      result_desc: get_arr(second_index, table_data, "result_desc"),
      suggestion: get_arr(second_index, table_data, "suggestion")[0],
      tag:get_arr(second_index, table_data, "tag")[0],
      target:get_arr(second_index, table_data, "target")[0]
    });
  });
  return table_data_2;
};
const start = (data) => {
  data.some((one) => {
    const second_index_obj = get_second_index_obj(one.table_data);
    one.table_data = set_table_data_2(second_index_obj, one.table_data);
    one.activeName = 1;
  });

  return data;
};
export const whitewash_list_filter_data = (data) => {
  return start(JSON.parse(JSON.stringify(data)));
};
