<template>
  <div class="wCollapse">
    <!-- <el-collapse v-for="(quota, i) in quotaData" :key="i" accordion v-model="quota.activeName">
      <el-collapse-item>
        <template slot="title">
          <div class="row aline-item align-items-center pd20">
            <s-title :name="quota.title" :id="quota.title"></s-title>
            <el-tag style="background:#F2637B;color:#fff" type="danger">{{ quota.table_data.length }}个异常点</el-tag>
          </div>
        </template>
        <div class="pd20 pd_t_b20">
          <c-table :tableData="quota.table_data"></c-table>
        </div>
      </el-collapse-item>
    </el-collapse> -->
    <div class="title">
      <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
      粉饰识别明细
    </div>
    <div class="tableBox">
      <div v-for="(item, key, index) in quotaData" :key="index">
        <div class="row align-items-center mt-3 mb-3 text-left">
          <div class="boxt"></div>
          <div class="titleBox">
            {{ item.title }} {{ item.table_data.length }}
          </div>
        </div>
        <c-table :tableData="item.table_data"></c-table>
        <!-- <el-table
          :data="item.table_data"
          :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
          style="width: 100%; font-size: 16px"
        >
          <el-table-column prop="second_index" label="异常指标" width="150">
            <template slot-scope="{ row }">
              <div>{{ row.second_index }}</div>
              <div class="mt-1">
                <el-tag
                  v-show="row.tag == '提示信息'"
                  style="color: #3a85fd; border-color: #3a85fd; font-size: 12px"
                  effect="plain"
                  >提示信息</el-tag
                >
                <el-tag
                  v-show="row.tag == '需要关注'"
                  style="color: #ffa105; border-color: #ffa105; font-size: 12px"
                  effect="plain"
                  >需要关注</el-tag
                >
                <el-tag
                  v-show="row.tag == '重点关注'"
                  style="color: #f12c5d; border-color: #f14c5d; font-size: 12px"
                  effect="plain"
                  >重点关注</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="result_desc" label="异常描述" width="300">
            <template slot-scope="{ row }">
              <div v-html="row.result_desc"></div>
            </template>
          </el-table-column>
          <el-table-column prop="outlier" label="异常详情" width="650">
            <template slot-scope="{ row }">
              <div v-html="row.outlier"></div>
            </template>
          </el-table-column>
          <el-table-column prop="suggestion" label="建议"></el-table-column>
        </el-table> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    STitle: require("@/components/title/title.vue").default,
    CTable: require("./CTable").default,
  },

  data() {
    return {
      flag: true,
      quotaData: [],
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.tableBox {
  width: 100%;
  padding: 0px 20px;

  .boxt {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #3a85fd;
    margin-right: 5px;
  }

  .titleBox {
    font-size: 18px;
    font-weight: 550;
    color: #333;
  }
}

.el-collapse {
  border: 0 !important;
}

.el-collapse-item__wrap {
  border: 0 !important;
}

.wCollapse {
  background: #fff;
  .title {
    padding: 15px 0px 0px 15px !important;
  }
  /deep/ * {
    // overflow: inherit !important;
  }

  > div {
    // margin-bottom: 20px;
  }

  /deep/ .el-collapse-item__header {
    // border-bottom: 1px solid #f0f0f0;
  }

  .pd20 {
    padding: 0 20px;
  }

  .pd_t_b20 {
    padding: 20px;
  }
}
</style>
