<template>
  <div
    class="ctbale el-table el-table--fit el-table--enable-row-transition el-table--small"
    style="width: 100%; color: #333333"
  >
    <div class="el-table__header-wrapper">
      <table
        cellspacing="0"
        cellpadding="0"
        border="0"
        class="el-table__header"
        style="width: 100%"
      >
        <!-- <colgroup>
          <col name="el-table_1_column_1" width="13%" />
          <col name="el-table_1_column_2" width="30%" />
          <col name="el-table_1_column_3" width="40%" />
          <col name="el-table_1_column_4" width="15%" />
        </colgroup> -->
        <thead class="has-gutter">
          <tr class="">
            <th
              colspan="1"
              rowspan="1"
              class="br el-table_1_column_1 is-leaf"
              width="13%"
            >
              <div class="cell">异常指标</div>
            </th>
            <th
              colspan="1"
              rowspan="1"
              class="el-table_1_column_2 is-leaf"
              width="30%"
            >
              <div class="cell">异常描述</div>
            </th>
            <th
              colspan="1"
              rowspan="1"
              class="el-table_1_column_3 is-leaf"
              width="40%"
            >
              <div class="cell">异常详情</div>
            </th>
            <th
              colspan="1"
              rowspan="1"
              class="el-table_1_column_4 is-leaf"
              width="15%"
            >
              <div class="cell">操作</div>
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="el-table__body-wrapper is-scrolling-none">
      <table
        cellspacing="0"
        cellpadding="0"
        border="0"
        class="el-table__body"
        style="width: 100%"
      >
        <!-- <colgroup>
          <col name="el-table_1_column_1" width="152" />
          <col name="el-table_1_column_2" width="400" />
          <col name="el-table_1_column_3" width="400" />
          <col name="el-table_1_column_4" width="180" />
        </colgroup> -->
        <!-- <tbody>
          <div
            v-for="(row, i) in tableData"
            :key="i"
            style="width: 1070px"
          >
            <tr class="el-table__row expanded">
              <td
                rowspan="2"
                width="220"
                colspan="1"
                class="br el-table_1_column_1"
              >
                <div class="cell">{{ row.second_index }}</div>
                <div >
                  <el-tag class="tag" :type="row.tag=='高危异常点'?'danger':'warning'">{{row.tag}}</el-tag>
                </div>
              </td>
              <td
                rowspan="1"
                width="620"
                colspan="1"
                class="el-table_1_column_2"
              >
                <div class="cell">
                  <div v-for="(item, i) in row.result_desc" :key="i">
                    <summary-text :text="item"></summary-text>
                  </div>
                </div>
              </td>
              <td
                rowspan="1"
                width="550"
                colspan="1"
                class="br el-table_1_column_3"
              >
                <div class="cell">
                  <div v-for="result_desc in row.outlier" :key="result_desc">
                    <summary-text :text="result_desc"></summary-text>
                  </div>
                </div>
              </td>
              <td
                rowspan="2"
                width="240"
                colspan="1"
                class="el-table_1_column_4"
              >
                <div class="cell">
                  <a
                    class="el-link el-link--primary"
                    style="color: rgb(25, 137, 250)"
                    ><span
                      style="font-size: 16px"
                      @click="show = !show"
                      class="el-link--inner"
                      >{{ show ? "收起图表" : "展示图表" }}</span
                    >
                  </a>
                </div>
              </td>
            </tr>
            <tr class="el-table__row expanded">
              <td rowspan="1" colspan="2" class="br el-table_1_column_2">
                <div class="cell">
                  <div>建议：</div>
                  <div>
                    <summary-text :text="row.suggestion"></summary-text>
                  </div>
                </div>
              </td>

            </tr>
            <tr v-show="show">
              <td colspan="5" class="el-table__expanded-cell">
                <div class="chart_box">
                  <div
                    style="width: 48%"
                    v-for="(chart, index) in row.图标"
                    :key="index"
                  >
                    <div>
                      <line-bar
                        :rule_name="chart.rule_name"
                        :series="chart.y"
                        :date="chart.date"
                      ></line-bar>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </div>
        </tbody> -->
        <tbody style="width: 100%">
          <div v-for="(row, i) in tableData" :key="i" style="min-width: 100%">
            <table-row :row="row"></table-row>
          </div>
        </tbody>
      </table>
    </div>
    <div class="el-table__column-resize-proxy" style="display: none"></div>
  </div>
</template>
<script>
export default {
  components: {
    // LineBar: require("./line_bar").default,
    // SummaryText: require("@/components/SummaryText/index.vue").default,
    TableRow: require("./TableRow").default,
  },
  props: {
    tableData: {
      default: [],
      type: Array,
      // flag: Boolean,
    },
  },

  data() {
    return {
      // show: false,
      // height:{
      //     marginLeft: '10px',
      //     height: '24px',
      //     background: rgba(241,76,93,0.2),
      //     border: '1px solid #F2637B',
      //     borderRadius: '3px',
      //     color:'#F2637B',
      //     lineHeight: '24px',
      // },
      // bottom:{
      //     marginLeft: '10px',
      //     height: '24px',
      //     background: rgba(255,161,5,0.12),
      //     border: '1px solid #FFA105',
      //     borderRadius: '3px',
      //     color:'#FFA105',
      //     lineHeight: '24px',
      // }
    };
  },
  methods: {
    getOneEle() {
      return [];
    },
  },
};
</script>
<style lang="less" scope>
.tag {
  margin-left: 10px;
  height: 24px;
  line-height: 22px;
}
.el-table th {
  background-color: #e8f4ff !important;
}
.ctbale {
  font-family: PingFangSC-Regular !important;
  font-size: 16px !important;
  line-height: 24px !important;
  .el-table__header {
    .has-gutter {
      background-color: #e8f4ff;
      font-family: Helvetica;
      font-size: 16px !important;
      color: #333333 !important;
      letter-spacing: 0 !important;
      line-height: 24px !important;
      .operation {
        padding-left: 0 !important;
      }
    }
  }
  .br {
    border-right: 1px solid #f0f0f0;
  }
  .bb {
    border-bottom: 1px solid #f0f0f0;
  }
  .chart_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      margin-bottom: 20px;
    }
  }
  .cell {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 24px;
  }
  .weigth {
    font-size: 16px;
    color: #333333;
    // font-weight: 500;
  }
}
</style>
