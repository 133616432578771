<template>
  <div style="width: 100%">
    <tr class="el-table__row expanded">
      <td rowspan="2" width="13%" colspan="1" class="br el-table_1_column_1">
        <div class="cell">{{ row.second_index }}</div>
        <div>
          <el-tag class="tag" v-show="row.tag == '重点关注'" type="danger">{{
            row.tag
          }}</el-tag>
          <el-tag class="tag" v-show="row.tag == '需要关注'" type="warning">{{
            row.tag
          }}</el-tag>
          <el-tag class="tag" v-show="row.tag == '提示信息'">{{
            row.tag
          }}</el-tag>
        </div>
      </td>
      <td rowspan="1" width="30%" colspan="1" class="el-table_1_column_2">
        <div class="cell">
          <div v-for="(item, i) in row.result_desc" :key="i">
            <summary-text :text="item"></summary-text>
          </div>
        </div>
      </td>
      <td rowspan="1" width="40%" colspan="1" class="br el-table_1_column_3">
        <div class="cell" style="padding-left: 15px">
          <div v-for="result_desc in row.outlier" :key="result_desc">
            <summary-text :text="result_desc"></summary-text>
          </div>
        </div>
      </td>
      <td rowspan="2" width="15%" colspan="1" class="el-table_1_column_4">
        <div class="cell">
          <a class="el-link el-link--primary" style="color: rgb(25, 137, 250)"
            ><span
              style="font-size: 16px"
              @click="show = !show"
              class="el-link--inner"
              >{{ show ? "收起图表" : "展示图表" }}</span
            >
          </a>
          <!-- <a class="el-link el-link--primary" style="color: rgb(25, 137, 250);margin-left:15px"
            v-show="row.tag !== '提示信息'">
            <span style="font-size: 16px;" @click="cldialogVisible(row)" class="el-link--inner">补充材料</span>
          </a> -->
        </div>
      </td>
    </tr>
    <tr class="el-table__row expanded">
      <td rowspan="1" colspan="2" class="br el-table_1_column_2">
        <div class="cell">
          <div>建议：</div>
          <div style="width: 100%">
            <summary-text :text="row.suggestion"></summary-text>
          </div>
        </div>
      </td>
    </tr>
    <tr v-show="show">
      <td colspan="5" class="el-table__expanded-cell">
        <div class="chart_box">
          <div
            style="width: 49%"
            v-for="(chart, index) in row.图标"
            :key="index"
          >
            <div>
              <line-bar
                :rule_name="chart.rule_name"
                :series="chart.y"
                :date="chart.data"
              ></line-bar>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <el-dialog
      title="补充材料"
      :visible.sync="dialogVisible"
      width="550px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div>
        <div class="box" v-show="approval == true">
          <i class="el-icon-error" style="color: red"></i>
          评级申请已启动（或已完成），补充材料无法修改。
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="补充内容" prop="desc">
            <el-input
              :disabled="role_kind != 1 || approval == true"
              type="textarea"
              resize="none"
              min-height="100"
              placeholder="请补充并解释相关异常情况"
              style="width: 88%"
              v-model="ruleForm.desc"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="上传附件"
            v-show="role_kind == 1"
            style="width: 400px"
          >
            <el-upload
              class="upload-demo"
              drag
              action
              name="file"
              :on-exceed="handleExceed"
              :auto-upload="false"
              :limit="1"
              show-file-list
              :file-list="filelist"
              :on-change="handleChansge"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
                <div style="color: #999; margin: 0; padding: 0">
                  只能上传.jpg .png .pdf等图片格式文件
                </div>
              </div>
            </el-upload>
          </el-form-item>
          <div style="width: 75%; margin: 0 auto">
            <div
              v-for="item in dataList"
              :key="item"
              class="row justify-content-between"
              style="font-size: 18px"
            >
              <div class="row">
                <i style="color: #999" class="el-icon-document mr-2"></i>
                <div sty class="fname" :title="item.fname">
                  {{ item.fname }}
                </div>
              </div>
              <div>
                <a
                  ><i
                    style="color: #1989fa"
                    @click="imageView(item.fid, item.fname)"
                    class="el-icon-view mr-3"
                  ></i
                ></a>
                <a
                  ><i
                    style="color: #1989fa"
                    @click="down(item.fid, item.fname)"
                    class="el-icon-download mr-3"
                  ></i
                ></a>
                <a v-if="role_kind == 1">
                  <i
                    style="color: #1989fa"
                    @click="removeList(item.fid)"
                    class="el-icon-delete"
                  ></i>
                </a>
              </div>
            </div>
          </div>
          <div class="mt-4 text-center">
            <el-button
              class="ml-5"
              style="width: 120px"
              type="pain"
              @click="handleDialogClose"
              >取 消</el-button
            >
            <el-button
              class="ml-5"
              :disabled="role_kind != 1"
              style="width: 120px"
              type="primary"
              @click="submitForm()"
              >确 定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  my_upload,
  my_uploadText,
  my_getTextpg,
  remove_attach,
  downloadIm,
} from "@/api";
import * as sesstion from "@/store/localStorage";
export default {
  components: {
    LineBar: require("./line_bar").default,
    SummaryText: require("@/components/SummaryText/index.vue").default,
  },
  props: {
    row: Object,
  },
  data() {
    return {
      file: "",
      // filename: "",
      filelist: [],
      flags: "",
      upFile: new FormData(),
      ruleForm: {
        desc: "",
      },
      rules: {
        desc: [
          {
            required: true,
            message: "请补充并解释相关异常情况",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,
      Visible: false,
      show: false,
      series: [
        {
          name: "蒸发量",
          type: "bar",

          data: [999999, 999999, 999999, 999999],
        },
        {
          name: "降水量",
          type: "bar",
          data: [999999, 999999, 999999, 999999],
        },
        {
          name: "平均温度",
          type: "line",
          yAxisIndex: 1,
          data: [2.0, 2.2, 3.3, 99],
          symbol: "circle",
          symbolSize: 12,
        },
      ],
      imgL: "",
      target: "",
      attachments: [],
      dataList: [],
      role_kind: sesstion.getLocalData("userData").role_kind,
      approval: sesstion.getLocalData("approval"),
      url: "",
    };
  },
  methods: {
    // 点击补充材料获取列表
    cldialogVisible(value) {
      this.target = value.target;
      let company = sesstion.getLocalData("companyData");
      let param = {
        ...company,
        target: value.target,
        report_year: company.year,
      };
      my_getTextpg(param).then((res) => {
        // console.log(res)
        // this.filelist=res.data.attachments
        this.dataList = res.data.attachments;
        this.ruleForm.desc = res.data.desc;
      });
      this.dialogVisible = true;
    },
    //关闭弹窗 移除数据
    handleDialogClose() {
      this.filelist = [];
      this.file = "";
      this.dataList = [];
      this.dialogVisible = false;
    },
    // 文件个数限制
    handleExceed(e) {
      // 判断是否只能上传一个文件，超过提示报错
      // console.log(e);
      this.$message.error({
        title: "错误",
        message: "只能上传一个文件",
      });
    },
    handleChansge(file, fileList) {
      // let name = file.name;
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!/\.(img|image|PNG|png|jpg|JPG|jpeg|pdf|PDF)$/.test(file.name)) {
        this.$message.error({
          title: "错误",
          message: "上传文件只能为图片格式或PDF文件",
        });
        this.file = "";
        return false;
      }
      if (!isLt2M) {
        this.$message({
          message: "上传文件大小不能超过 5MB!",
          type: "error",
        });
        this.file = "";
        return false;
      } else {
        this.file = file.raw;
        // this.filename = file.name;
      }
    },
    submitForm() {
      if (this.ruleForm.desc == "" || this.ruleForm.desc == undefined) {
        return this.$message.error("请填写并补充异常情况说明");
      } else {
        let that = this;
        if (this.file == "") {
          let company = sesstion.getLocalData("companyData");
          this.attachments = [];
          let param = {
            ...company,
            attachments: this.attachments,
            target: this.target,
            desc: this.ruleForm.desc,
            report_year: company.year,
          };
          my_uploadText(param).then((res) => {
            that.$message({
              title: "上传成功",
              message: "上传成功",
              type: "success",
            });
            let company = sesstion.getLocalData("companyData");
            let param = {
              ...company,
              target: this.target,
              report_year: company.year,
            };
            my_getTextpg(param).then((res) => {
              // console.log(res)
              this.dataList = res.data.attachments;
            });
            this.attachments = [];
            this.filelist = [];
            this.file = "";
            this.dataList = [];
            this.dialogVisible = false;
          });
        } else {
          // 文件形式的需要用 formData形式
          let formData = new FormData();
          formData.append("fcontent", this.file);
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          my_upload(formData, config)
            .then((res) => {
              if (this.dataList == undefined) {
                this.attachments.push(res.data.fid);
              } else {
                this.dataList.map((item) => {
                  this.attachments.push(item.fid);
                });
                this.attachments.push(res.data.fid);
              }
              let company = sesstion.getLocalData("companyData");
              let param = {
                ...company,
                attachments: this.attachments,
                target: this.target,
                desc: this.ruleForm.desc,
                report_year: company.year,
              };
              my_uploadText(param).then((res) => {
                // console.log(res);
                that.$message({
                  title: "上传成功",
                  message: "上传成功",
                  type: "success",
                });
                let company = sesstion.getLocalData("companyData");
                let param = {
                  ...company,
                  target: this.target,
                  report_year: company.year,
                };
                my_getTextpg(param).then((res) => {
                  // console.log(res)
                  this.dataList = res.data.attachments;
                });
                this.attachments = [];
                this.filelist = [];
                this.file = "";
                this.dataList = [];
                this.dialogVisible = false;
              });
            })
            .catch((err) => {
              // console.log(err);
              that.$notify.error({
                title: "错误",
                message: "上传过程出错啦",
              });
            });
        }
      }
    },

    // 删除
    removeList(value) {
      let company = sesstion.getLocalData("companyData");
      let param = {
        ...company,
        attachment: value,
        report_year: company.year,
        target: this.target,
      };
      // console.log(param);
      remove_attach(param).then((res) => {
        console.log(res);
        if (res.status == 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          let params = {
            ...company,
            target: value.target,
            report_year: company.year,
            target: this.target,
          };
          my_getTextpg(params).then((res) => {
            // console.log(res)
            this.dataList = res.data.attachments;
          });
        }
      });
    },
    //下载
    down(id, name) {
      var tokenLi = sesstion.getLocalData("userData").auth_token;
      var url = `${window.BASE_URL}/whitewash/rp-remark/download/?fid=` + id;
      const x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.setRequestHeader("Authorization", "Bearer " + tokenLi);
      x.responseType = "blob";
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = url;
        a.download = id;
        a.click();
      };
      x.send();
      // window.open(`${window.BASE_URL}/whitewash/rp-remark/download/?fid=` + id)
    },

    // imageView(path) {
    // this.Visible=true
    // this.url = `${window.BASE_URL}/whitewash/rp-remark/download/?fid=` + path
    // // window.open(this.url)
    // sesstion.setLocalData('imgUrl', this.url)
    // setTimeout(() => {
    //   window.open("/#/img")
    // }, 200)
    // },
    imageView(path, name) {
      // <a href="javascript:demo();">预览</a>
      let url = `${window.BASE_URL}/whitewash/rp-remark/download/?fid=` + path;
      let str = name.substring(name.length - 4);
      if (str == ".pdf" || str == ".PDF") {
        this.previewPDF(url, name);
      } else {
        this.down(path, name);
      }
    },
    previewPDF(url, filename) {
      this.getBlob(url, (blob) => {
        this.preview(blob, filename);
      });
    },
    getBlob(url, cb) {
      var tokenLi = sesstion.getLocalData("userData").auth_token;
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.setRequestHeader("Authorization", "Bearer " + tokenLi);
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response);
        }
      };
      xhr.send();
    },
    preview(blob, filename) {
      blob = new Blob([blob], { type: "application/pdf" });
      let furl = URL.createObjectURL(blob);
      window.open(furl);
    },
  },
};
</script>
<style lang="less" scoped>
.fname {
  font-size: 14px;
  width: 250px !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dialog-img {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  top: 0;
  left: 0;
}

.dialog-img-wrap {
  position: absolute;
  width: 600px;
  height: 350px;
  left: 50%;
  top: 50%;
  margin: -175px 0 0 -300px;
}

.dialog-img .dialog-close {
  position: absolute;
  right: -10px;
  top: -10px;
  background: #000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  color: red;
  line-height: 40px;
  font-size: 26px;
  font-style: normal;
}

.dialog-img-wrap img {
  width: 600px;
  height: 350px;
}

/deep/.el-upload-dragger {
  // width: 100% !important;
  min-width: 90% !important;
  max-width: 100% !important;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  color: #333333 !important;
}

.ctbale {
  .br {
    border-right: 1px solid #f0f0f0;
  }

  .bb {
    border-bottom: 1px solid #f0f0f0;
  }

  .chart_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      margin-bottom: 20px;
    }
  }

  .cell {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 24px;
  }

  .weigth {
    font-size: 16px;
    color: #333333;
    // font-weight: 500;
  }

  .box {
    font-size: 14px;
    color: #333333;
    background: #fef0f0;
    width: 90%;
    height: 35px;
    margin-bottom: 15px;
    margin-left: 10px;
    border-radius: 4px;
    padding: 5px;
  }
}
</style>
