<template>
  <div
    v-html="text"
    :title="text | textFilter"
    class="ellipsis"
    :style="`width:${width}`"
  ></div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: "100%",
    },
    text: {
      type: String,
    },
  },
  filters: {
    textFilter(val) {
      var div = document.createElement("div");

      div.innerHTML = val;

      return div.innerText;
    },
  },
};
</script>
<style lang="less" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
  color: #000000;
  font-family: PingFang SC;
  font-weight: 500;
}
</style>
