<template>
  <div>
    <div class="chart_title">
      <div class="row justify-content-between" style="width:100%">
        <div style="color:#333;font-size:18px">{{rule_name}}</div>
        <el-select size="small" style="width: 100px; " v-model="unit">
          <el-option :value="10000" label="万元"></el-option>
          <el-option :value="1" label="元"></el-option>
        </el-select>
      </div>
    </div>
    <div style="width: 600px; height: 380px" ref="echarEle"></div>
  </div>
</template>
<script>
export default {
  props: {
    series: Array,
    rule_name: String,
    date: Array,
  },
  created() {},

  mounted() {
    this.init();
  },
  data() {
    return {
      unit: 10000,
    };
  },
  watch: {
    unit() {
      this.init();
    },
    date() {
      this.init();
    },
  },
  methods: {
    setBarWidth(series) {
      let barData = [];
      let lineData = [];

      series.map((item) => {
        item.type == "bar" && (item.barWidth = 35);
      });
    },
    setBarData(series) {
      series.map((item) => {
        if (item.type == "bar") {
          item.data = item.data.map((barNum) => {
            if(barNum==''){
              return barNum
            }else{
              return (barNum / this.unit).toFixed(2);
            }
          });
        } else {
          item.data = item.data.map((lineNum) => {
            if(lineNum==''){
              return lineNum
            }else{
              return lineNum.toFixed(2);
            }
            
          });
        }
      });
    },
    get_symbol(series) {
      const key = "周转率";
      let symbol = "%";
      series.map((item) => {
        // console.log(item.name);
        if (item.name.includes(key)) {
          symbol = "";
        }
      });
      return symbol;
    },
    init() {
      let series = [];
      try {
        series = JSON.parse(JSON.stringify(this.series));
      } catch (error) {
        return false;
      }
      // series.map((item)=>{
      //   // console.log(item.data);
      //   item.data.map((i,index)=>{
      //     if(i==''){
      //       item.data[index]=0
      //     }
      //   })
      // })
      // console.log(series);
      this.setBarData(series);
      this.setBarWidth(series);
      const symbol = this.get_symbol(series);

      var option = {
        legend: {
          top: "0",
          // type: "scroll",
        },
        // title: {
        //   left: "center",
        //   text: `【${this.rule_name}】`,
        //   textStyle: {
        //     fontSize: 14,
        //     fontWeight: 400,
        //   },
        // },
        backgroundColor: "#fff",
        color: ["#529EE2", "#F88E36", "#FAD33F","#3EBB9F"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
          formatter: (par) => {
            var ele = ``;
            par.map((item) => {
              // if (item.seriesName.includes("行业")) return false;
              ele += `<div>${item.seriesName}: ${item.data}</div>`;
            });
            return ele;
          },
        },
        grid: {
          // left: `${dviationLeft}%`,
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: this.date,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              alignWithLabel: false,

              lineStyle: {
                color: "#F0F0F0",
                width: "4",
              },
            },
            axisLabel: {
              color: "#999999",
            },
            axisTick: {
              show: false,
            },

            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",

            // interval: max_obj.barMax / 5,
            axisLabel: {
              formatter: "{value} ",
              color: "#999999",
            },
            axisLine: {
              show: false,
              alignWithLabel: false,
            },
            axisTick: {
              show: false,
            },
            // 横线---颜色
            splitLine: {
              show: true,

              lineStyle: {
                type: "dashed",
                color: "#F0F0F0",
              },
            },
          },
          {
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            type: "value",

            // min: max_obj.lineMin < 0 ? max_obj.lineMin : 0,
            // max: max_obj.lineMax,

            // interval: max_obj.lineMax / 5,
            // 图表右侧字体颜色
            axisLabel: {
              color: "#999999",
              formatter: `{value}${symbol}`,
            },
          },
        ],
        series: series,
      };
      this.$echarts.init(this.$refs.echarEle).setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.row{
  padding-left: 50px;
  padding-right: 50px;
  div{
    font-weight: 550;
    font-size: 16px;
  }
}
.chart_title {
  display: flex;
  justify-content: space-between;
}
</style>
