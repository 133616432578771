import service from "@/common/request.js";

/**
 * get_company_list
 * @param {company_code} string
 * @param {company_name} string
 * @param {report_type} string
 * @param {report_time} string
 * @param {year} string
 */

import { whitewash_list_filter_data } from "./filterData/whitewash";
//
export function get_whitewash_list(param) {
  return new Promise((a, b) => {
    service
      .post("/whitewash/detail/", param)
      .then(({ data }) => {
        data.fold_data = whitewash_list_filter_data(data.fold_data);
        a(data);
      })
      .catch(() => {
        b();
      });
  });
}


export const get_whitewash = (prame) => {
  return service.post("/whitewash/collect/", prame);
};

/**
 * 事业单位财务粉饰识别
 * @param {*} param 
 * @returns 
 */
export const causeDetail = (param) => {
  return new Promise((a, b) => {
    service
      .post(`institution/whitewash/detail/`, param)
      .then(({ data }) => {
        data.data.fold_data = whitewash_list_filter_data(data.data.fold_data);
        a(data);
      })
      .catch(() => {
        b();
      });
  })
}