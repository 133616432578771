<template>
  <span class="summary_text" v-html="text"> </span>
</template>
<script>
export default {
  props: {
    text: String,
  },
};
</script>
<style lang="less">
.summary_text {
  .fc887c {
    color: #fc887c;
  }
  .fc7566 {
    color: #fc7566;
  }
}
</style>
