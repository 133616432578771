<template>
  <div class="o_right">
    <div class="r_item" v-show="high_total != 0">
      <div class="o_type">重点关注 ({{ high_total }})</div>
      <div class="s_item_box">
        <div
          class="s_item"
          v-for="(item, i) in high_data && high_data.arr"
          :key="i"
        >
          <div
            class="o_title"
            @click="clickScrroll(item)"
            style="cursor: pointer"
          >
            <s-ellipsis
              :text="`<span >${item.title}</span><span style='color:#fc7566'> (${item.arr.length})</span>`"
            >
            </s-ellipsis>
          </div>
          <div class="sub" v-for="(item, i) in item.arr" :key="i">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="r_item" v-show="middle_total != 0">
      <div class="o_type">需要关注 ({{ middle_total }})</div>
      <div class="s_item_box">
        <div
          class="s_item"
          v-for="(item, i) in middle_data && middle_data.arr"
          :key="i"
        >
          <div
            class="o_title"
            @click="clickScrroll(item)"
            style="cursor: pointer"
          >
            <s-ellipsis
              :text="`<span >${item.title}</span><span style='color:#fec400'> (${item.arr.length})</span>`"
            >
            </s-ellipsis>
          </div>
          <div class="sub" v-for="(item, i) in item.arr" :key="i">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="r_item" v-show="look_total != 0">
      <div class="o_type">提示信息 ({{ look_total }})</div>
      <div class="s_item_box">
        <div
          class="s_item"
          v-for="(item, i) in look_data && look_data.arr"
          :key="i"
        >
          <div
            class="o_title"
            @click="clickScrroll(item)"
            style="cursor: pointer"
          >
            <s-ellipsis
              :text="`<span >${item.title}</span><span style='color:#3a85fd'> (${item.arr.length})</span>`"
            >
            </s-ellipsis>
          </div>
          <div class="sub" v-for="(item, i) in item.arr" :key="i">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    SEllipsis: require("@/components/ellipsis/index").default,
  },
  props: {
    middle_data: Object,
    high_data: Object,
    look_data: Object,
    middle_total: Number,
    high_total: Number,
    look_total: Number,
  },
  data() {
    return {};
  },
  methods: {
    clickScrroll(item) {
      var slect = `#${item.title}`;
      if (window.pageYOffset < 400) {
        $(document).scrollTop(400);
      }
      setTimeout(() => {
        var t_a = $(slect).offset();
        $("html,body").animate({ scrollTop: t_a.top - 74 - 60 + "px" }, 500);
      }, 200);
    },
  },
};
</script>
<style lang="less" scoped>
.o_right {
  height: 350px;
  overflow-y: auto;
  flex: 1;
  padding-left: 20px;
  > .r_item {
    display: flex;
    > .o_type {
      font-family: PingFang SC;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
      margin-right: 12px;
      font-weight: bold;
      width: 14%;
    }
    > .s_item_box {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      > .s_item {
        width: 220px;
        margin-bottom: 5px;

        > .o_title {
          font-size: 16px;
          line-height: 22px;
          color: #333333;
          margin-bottom: 8px;
          padding-right: 10px;
          box-sizing: border-box;
        }
        > .sub {
          color: #333333;
          font-size: 16px;
          line-height: 22px;
          padding-bottom: 5px;
        }
      }
    }
  }
  > .r_item:nth-child(1) {
    > .o_type {
      color: #fc7566;
    }
    span {
      color: #fc7566;
    }
  }
  > .r_item:nth-child(2) {
    > .o_type {
      color: #fec400;
    }
    span {
      color: #fec400;
    }
  }
  > .r_item:nth-child(3) {
    > .o_type {
      color: #3a85fd;
    }
    span {
      color: #3a85fd;
    }
  }
}
</style>
