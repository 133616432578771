<template>
  <div id="Status">
    <div class="title">
      <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
      总体概览
    </div>
    
    <div class="row justify-content-around">
      <!-- 左边正太图-->
      <div style="overflow: hidden; width: 50%">
        <div style=" margin-top: 5px; margin-left: 30px; margin-bottom: 10px; font-weight: 550; font-size: 18px;color:#333">
          粉饰识别结果
        </div>
        <div
          class="fenshi"
          style="width: 100%; overflow: hidden; margin-top: 5px;color:#333"
        >
          <div class="row justify-content-center">
            <div class="row align-items-center"  style="padding-right:15px">
              <div class="bk" style="background:#529EE2"></div>
              <div style="font-size:12px">无明显异常</div>
            </div>
            <div class="row align-items-center" style="padding-right:15px">
              <div class="bk" style="background:#FAD33F"></div>
              <div style="font-size:12px">需要关注</div>
            </div>
            <div class="row align-items-center" style="padding-right:15px">
              <div class="bk" style="background:#F88E36"></div>
              <div style="font-size:12px">重点关注</div>
            </div>
            
          </div>
        
        <div v-bind:style="{position:positions,top:top,left:left}">
          <i class="el-icon-caret-bottom" style="color:#409eff;width:18px;height:18px"></i>
          <span style="font-size:14px">{{company.S_or_B_name}}</span>
        </div>
          <img class="img" src="@/assets/img/detail/geaus.png" alt="" />
        </div>
      </div>
      <div
        class="col-lg-6 col-md-12 flex-column row bgc_w color-4d"
        style="overflow: hidden"
      >
        <div
          style="
            margin-top: 5px;
            margin-left: 20px;
            margin-bottom: 15px;
            font-weight: 550;
            font-size: 18px;
            color:#333
          "
        >
          粉饰识别历史变化
        </div>
        <!-- 折线 -->
        <div>
          <!-- <SecurityLine :date="truth_index.name" :data="truth_index.value" /> -->
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as sesstion from "@/store/localStorage";
export default {
  components: {
    // SecurityLine: require("@/components/line/Line").default,
  },
  props: {
    truth_index: Object,
    Status:String,
  },
  data() {
    return {
      company: sesstion.getLocalData("company"),
        positions: 'absolute',
        top: '65%',
        left: '5%',
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      // console.log(this.Status);
      if(this.Status=='重点关注'){
        this.top='65%'
        this.left='5%'
      }
      if(this.Status=='需要关注'){
        this.top="20%"
        this.left="54%"
      }
      if(this.Status=="无明显异常"){
        this.top="60%"
        this.left="73%"
      }
    }
  },
  watch: {
    Status(){
      this.init()
    }
  },
};
</script>

<style lang="less" scoped>
#Status {
  background: #fff;
  margin-bottom: 20px;
  overflow: hidden;
  padding-bottom: 30px;
  margin-top: 10px;
  height: 400px;
  border-radius: 4px;
  .bk{
    width: 24px;
    height: 14px;
    border-radius: 3px;
    margin-right: 5px;
  }
  .title {
    font-size: 20px;
    font-weight: 550;
    background: #fff;
     padding: 15px 0px 0px 20px;
     color:#333
  }
  .fenshi{
    position: relative;
    margin-left: 40px;
.positions{
  width: 120px;
  position: absolute;
  top: 40px;
  left: 373px;
}
    .img{
      margin-top: 30px;
      width: 90%;
      height: 220px;
    }
  }
}
</style>