<template>
  <div id="overview">
    <div class="o_desc">
      针对{{ company_name }}进行分析，
      <span>
        共发现
        <span class="spans">{{
          all_middle_total + all_high_total + look_total
        }}</span>
        个异常。
      </span>
      其中重点关注
      <span class="spans">{{ all_high_total }}</span> 个、需要关注
      <span class="spans_middle">{{ all_middle_total }}</span> 个、提示信息
      <span class="spans_look">{{ look_total }}</span> 个。
    </div>
    <o-right
      :middle_total="all_middle_total"
      :high_total="all_high_total"
      :middle_data="overview_data[1]"
      :high_data="overview_data[0]"
      :look_total="look_total"
      :look_data="overview_data[2]"
    ></o-right>
    <!-- <div style="padding-left: 45px" class="row">
      <o-left :middle_total="all_middle_total" :high_total="all_high_total"></o-left>
    </div> -->
  </div>
</template>
<script>
import * as sesstion from "@/store/localStorage";
export default {
  components: {
    STitle: require("@/components/title/title.vue").default,
    // OLeft: require("./left.vue").default,
    ORight: require("./right.vue").default,
  },
  data() {
    return {
      overview_data: [],
      company_name: "",
    };
  },
  created() {
    this.setCompayName();
  },

  methods: {
    setCompayName() {
      var company = sesstion.getLocalData("company");
      this.company_name = company.companyInfo
        ? company.companyInfo.name
        : company.name;
    },
  },

  computed: {
    middle_total() {
      let total = 0;
      this.overview_data.map((item, i) => {
        if (i == 1) {
          total += item.arr.length;
        }
      });
      return total;
    },
    all_middle_total() {
      let total = 0;
      this.overview_data.some((item, i) => {
        if (i == 1) {
          item.arr.map((item) => {
            return (total += item.arr.length);
          });
        }
      });
      return total;
    },
    all_high_total() {
      let total = 0;
      this.overview_data.some((item, i) => {
        item.arr.map((item) => {
          return (total += item.arr.length);
        });
        if (!i) return true;
      });
      return total;
    },
    high_total() {
      let total = 0;
      this.overview_data.some((item, i) => {
        total += item.arr.length;
        if (!i) return true;
      });
      // console.log(total);
      return total;
    },
    all_look_total() {
      let total = 0;
      this.overview_data.some((item, i) => {
        item.arr.map((item) => {
          return (total += item.arr.length);
        });
        if (!i) return true;
      });
      // console.log(total);
      return total;
    },
    look_total() {
      let total = 0;
      this.overview_data.some((item, i) => {
        if (i == 2) {
          item.arr.map((item) => {
            return (total += item.arr.length);
          });
        }
      });
      return total;
    },
  },
};
</script>
<style lang="less" scoped>
#overview {
  .title {
    font-size: 20px;
    font-weight: 550;
    background: #fff;
    padding: 15px 0px 0px 20px;
    color: #333;
  }

  .botton_line {
    width: 100%;
    border-top: 1px solid #ebeef5;
  }

  background: #fff;
  margin-bottom: 20px;
  overflow: hidden;
  padding-bottom: 20px;

  > .o_desc {
    color: #333;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    // margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    .spans {
      font-size: 24px;
      color: #fc7566;
      font-weight: 550;
    }
    .spans_middle {
      font-size: 24px;
      color: #fec400;
      font-weight: 550;
    }
    .spans_look {
      font-size: 24px;
      color: #3a85fd;
      font-weight: 550;
    }
  }
}
</style>
