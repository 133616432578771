<template>
  <div class="title-s">
    <div>
      <span class="title-s-line"></span>
      <span>{{ name }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Tilte",
  props: ["name"],
};
</script>
<style scoped lang="less">
.title-s {
  padding: 23px 8px;

  line-height: 32px;
  > div:nth-child(1) {
    font-size: 14px;
    font-family: PingFangSC-Medium;
    font-weight: 400;

    opacity: 1;
    position: relative;
    padding-left: 18px;
    font-size: 20px;
    color: #000000;
    .title-s-line {
      position: absolute;
      width: 8px;
      height: 8px;

      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: #238efa;
      border-radius: 50%;
     
    }
  }
}
</style>
