<template>
  <div>
    <div id="Status" v-if="getUserRole('whitewash_overview')">
      <div class="title test1">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        粉饰识别
      </div>
      <div class="row justify-content-around">
        <div style="overflow: hidden; width: 35%">
          <div
            style="
              margin-top: 5px;
              margin-left: 30px;
              font-weight: 550;
              font-size: 18px;
              color: #333;
            "
          >
            粉饰识别结果
          </div>
          <div
            class="mt-3"
            ref="echarEle"
            style="height: 320px; width: 100%"
          ></div>
          <div v-show="gaugeName == '明显异常'" class="text">
            粉饰识别结果为明显异常，发现报表中存在大量或重大异常情况。
          </div>
          <div v-show="gaugeName == '少量存疑'" class="text">
            粉饰识别结果为少量存疑，发现报表中存在少量异常情况。
          </div>
          <div v-show="gaugeName == '基本可信'" class="text">
            粉饰识别结果为基本可信，暂未发现报表中存在明显的异常情况。
          </div>
        </div>
        <div style="width: 65%">
          <div
            style="
              margin-top: 5px;
              margin-left: 20px;
              font-weight: 550;
              font-size: 18px;
              color: #333;
            "
          >
            异常点汇总
          </div>
          <div>
            <w-overview ref="WOverview"></w-overview>
          </div>
        </div>
      </div>
    </div>

    <w-collapse
      v-if="getUserRole('whitewash_details')"
      ref="WCollapse"
      class="test1"
    ></w-collapse>

    <div class="tableBox" v-if="getUserRole('custom_whitewash')">
      <div class="row align-items-center mt-3 mb-3 text-left">
        <div class="boxt"></div>
        <div class="titleBox">自定义粉饰点 {{ RuleData.pass.length }}</div>
      </div>
      <el-table
        :data="RuleData.pass"
        :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
        style="width: 100%; font-size: 16px"
      >
        <el-table-column
          prop="name"
          label="粉饰点名称"
          width="300"
        ></el-table-column>
        <el-table-column label="风险等级" width="200">
          <template slot-scope="{ row }">
            <el-tag
              v-show="row.level == '0'"
              style="color: #3a85fd; border-color: #3a85fd; font-size: 12px"
              effect="plain"
              >提示信息</el-tag
            >
            <el-tag
              v-show="row.level == '1'"
              style="color: #ffa105; border-color: #ffa105; font-size: 12px"
              effect="plain"
              >需要关注</el-tag
            >
            <el-tag
              v-show="row.level == '2'"
              style="color: #f12c5d; border-color: #f14c5d; font-size: 12px"
              effect="plain"
              >重点关注</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="description" label="异常描述"></el-table-column>
        <el-table-column prop="detail" label="异常详情">
          <template slot-scope="{ row }">
            <span>
              {{ row.detail || "--" }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-2">
        <div
          style="font-size: 16px; color: #f12c5d"
          class="mt-1"
          v-for="item in RuleData.errors"
          :key="item"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import status from "./Status/index.vue";
import {
  get_whitewash_list,
  get_whitewash,
  causeDetail,
} from "@/api/whitewash.js";
import {
  checkpoint,
  logActivity,
  FinWhitewashCal, //粉饰饼图
  causeWhitewash,
  rule_fails,
} from "@/api.js";

import * as sesstion from "@/store/localStorage";
export default {
  name: "Whitewash",
  dec: "财务粉饰识别",
  components: {
    status,
    WCollapse: require("./WCollapse/index.vue").default,
    WOverview: require("./overview/index").default,
    SPie: require("@/components/SPie/index").default,
  },
  data() {
    return {
      flag: true,
      nameStatus: 0,
      colors: "",
      truth_index: {},
      gaugeName: "",

      RuleData: {},
    };
  },

  created() {
    this.$VM[this.$route.name] = this;
  },
  mounted() {
    // this.$VM.PublicSearch.search();
  },
  computed: {},

  methods: {
    search(search_data) {
      const company = sesstion.getLocalData("company");
      const param = {
        ...search_data,
        menu_id: 2,
        industry_range: 0,
        industry_number: search_data.industry_code,
        industry_type: search_data.industry_name,
        parsing_time: search_data.year,
        report_year: search_data.year,
      };
      this.params = {
        ...param,
        stock_code: param.Stock_code,
        report_year: param.year,
        company_name: param.name,
      };

      checkpoint(param).then(({ data }) => {
        if (data.Status != 1) {
          this.$loadingHide(); //隐藏加载动画
          return this.$alert(data.Message, "提示", {
            confirmButtonText: "确定",
            type: "error",
          });
        } else {
          this.$loadingShow();
          if (company.company_type >= "3") {
            causeWhitewash({
              ...param,
              report_year: param.year,
            }).then(({ data }) => {
              let reasonReportMapping = {
                "00": "年报",
                "01": "三季报",
                "02": "半年报",
                "03": "一季报",
                "001": "一月报",
                "002": "二月报",
                "003": "三月报",
                "004": "四月报",
                "005": "五月报",
                "006": "六月报",
                "007": "七月报",
                "008": "八月报",
                "009": "九月报",
                "010": "十月报",
                "011": "十一月报",
                "012": "十二月报",
              };
              this.$store.commit(
                "setYears",
                `(${param.year}${reasonReportMapping[param.report_time]})`
              );

              this.$VM.CompanyDetail.generalEvaluation =
                data.content[0].content.horsepower.health_hp.rating;
              sessionStorage.generalEvaluation =
                data.content[0].content.horsepower.health_hp.rating;
              this.$loadingHide();
            });
          } else {
            logActivity(param);
            if (this.getUserRole("whitewash_overview")) {
              FinWhitewashCal({
                ...param,
                Stock_code: company.S_or_B_code ? company.S_or_B_code : "",
                company_name: company.S_or_B_code
                  ? company.companyInfo.name
                  : company.name,
              }).then((res) => {
                this.gaugeName = res.data.watch;
                this.nameStatus = res.data.value;
                this.colors = res.data.color;
                setTimeout(() => {
                  this.init(this.gaugeName);
                  this.$loadingHide();
                }, 2000);
              });
              get_whitewash(search_data)
                .then((res) => {
                  this.$refs.WOverview.overview_data = res.data.overview_data;
                  this.$loadingHide();
                })
                .catch((err) => {
                  this.$loadingHide();
                });
            }
            if (this.getUserRole("whitewash_details")) {
              get_whitewash_list(search_data)
                .then((res) => {
                  this.$refs.WCollapse.quotaData = res.fold_data;

                  this.$loadingHide();
                })
                .catch((err) => {
                  this.$loadingHide();
                });
            }

            if (this.getUserRole("custom_whitewash")) {
              rule_fails({
                ...search_data,

                report_year: search_data.year,
                style: "1",
              }).then(({ data }) => {
                // console.log(data);
                this.RuleData = data;
              });
            }
          }
        }
      });
    },
    init(name) {
      // let title = ''
      // if (name == '重点关注') {
      //   title = '明显异常'
      // }
      // if (name == '需要关注') {
      //   title = '少量存疑'
      // }
      // if (name == '无明显异常') {
      //   title = '基本可信'
      // }
      var option = {
        title: {
          show: true,
          subtext: name,
          textStyle: {
            fontSize: 26,
            color: this.colors,
          },
          subtextStyle: {
            color: this.colors,
            fontSize: 26,
          },
          top: "47%",
          left: "center",
        },
        // tooltip: {
        //   show: true,
        //   trigger: 'item',
        //   backgroundColor:'rgba(0,0,0,0.7)',
        //   borderColor : '#999',
        //   textStyle: {
        //     color:"#FFF",
        //     fontSize:16,
        //   },
        //   formatter:((item)=>{
        //     if(item.value==50){
        //       return "发现报表中存在大量或重大异常情</br>况，需要企业提供补充信息来说明</br>解释以增进报表可信度。"
        //     }
        //     if(item.value==30){
        //       return "发现报表中存在少量异常情况，建</br>议企业提供相关补充信息来说明以</br>增进报表可信度。"
        //     }
        //     if(item.value==10){
        //       return "暂未发现报表中存在明显的异常情</br>况，企业报表基本可信。"
        //     }
        //   })
        // },
        series: [
          {
            type: "gauge",
            min: 0,
            max: 60,
            splitNumber: 6,
            center: ["50%", "55%"],
            radius: "85%",
            z: 999,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: [
                  [0.33, "#3A85FD"],
                  [0.67, "#FAD33F"],
                  [1, "#F88E36"],
                ],
              },
            },
            splitLine: {
              distance: -18,
              length: 18,
              lineStyle: {
                color: "auto",
              },
            },
            axisTick: {
              distance: -12,
              length: 10,
              splitNumber: 10,
              lineStyle: {
                color: "auto",
              },
            },
            axisLabel: {
              color: "#333",
              fontSize: 18,
              distance: -30,
              fontWeight: 550,
              rotate: "tangential",
              formatter: function (value) {
                if (value == 10) {
                  return "基本可信";
                } else if (value === 30) {
                  return "少量存疑";
                } else if (value === 50) {
                  return "明显异常";
                }
                return "";
              },
            },

            pointer: {
              offsetCenter: [0, "-65%"],
              icon: "path://M512 512m-448 0a448 448 0 1 0 896 0 448 448 0 1 0-896 0Z",
              width: 25,
              length: 25,
              itemStyle: {
                color: "auto",
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: Number(this.nameStatus),
              },
            ],
          },
          {
            type: "gauge",
            startAngle: 0,
            endAngle: 360,
            center: ["50%", "55%"],
            radius: "75%",
            min: 0,
            max: 360,
            axisLine: {
              lineStyle: {
                width: 45,
              },
            },
            pointer: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
        ],
      };
      this.$echarts.init(this.$refs.echarEle).setOption(option); //可以解决echarts组件只加载一次问题
    },
  },
};
</script>

<style lang="less" scope>
#Status {
  background: #fff;
  margin-bottom: 20px;
  overflow: hidden;
  // padding-bottom: 30px;
  margin-top: 10px;
  // height: 550px;
  border-radius: 4px;

  .title {
    font-size: 20px;
    font-weight: 550;
    background: #fff;
    padding: 15px 0px 0px 20px;
    color: #333;
  }
}
.tableBox {
  background: #fff;
  width: 100%;
  padding: 20px;

  .boxt {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #3a85fd;
    margin-right: 5px;
  }

  .titleBox {
    font-size: 18px;
    font-weight: 550;
    color: #333;
  }
}
.btn {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;

  .el-button {
    font-size: 16px;
    font-weight: 550;
  }
}

.text {
  background: #e9f3ff;
  width: 100%;
  min-height: 30px;
  line-height: 30px;
  font-size: 16px;
  color: #333;
  padding: 10px;
  margin-left: 20px;
  margin-top: 10px;
}

.title {
  font-size: 20px;
  font-weight: 550;
  background: #fff;
  padding: 20px;
  color: #333;
}

#rad {
  min-height: 300px;
  width: 49%;
  overflow: hidden;
}
</style>
